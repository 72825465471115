thead {
  display: none;
  @include media-breakpoint-up(md) {
      display: table-header-group;
  }
}

#overview-notification {
  .header-accordion {
    > div {
      width: 30%;
    }
    button {
      width: 5%;
      outline: none;
    }
  }

  .attribute-btn {
    color: $primary;
    background: transparent;
    border: 0;
  }
}