.separator {
  margin: 0 1rem;
  background: $gray-middle;
  height: 2px;

  &:last-child {
    display: none;
  }
  &:first-child {
    display: none;
  }
}
#addressSearch {
  border: 4px solid #DFDFE0;
}
.contributor{
  &__full-company--warning {
    font-size: 0.85em;
  }
}

.bg-origin-depositor, .bg-origin-agent_tmc, .bg-origin-tmc{
  background-color: #F67032;
}

.bg-origin-agent, .bg-origin-undefined, .bg-origin-holder {
  background-color: $green;
}

.contributor-text{
  overflow-wrap : anywhere;
}

.manageableQuality-underline {
  text-decoration: underline;
}

.contributor-form {
  .validation-error {
    position: relative;
  }
}

.error-radio {
  left: -95px;
  bottom: -50px;
}