.notif-ellipsis {
  font-weight: bold;
  color: $secondary;

  &:hover {
    color: $secondary;
  }
}

.reception-confirm {
  font-size: 17px;
  font-weight: bold;
  color: $primary;
}