@mixin vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#mainHeader {
  background-color: $white;

  .nav {
    height: 100%;

    .nav-item {
      @include vertical-center;
      border-bottom: 4px solid transparent;

      &.active {
        font-weight: bold;
        border-color: $primary;
      }

      .logo {
        margin: 5px;
        img {
          height: 55px;
        }
      }

      .nav-link {
        @include vertical-center;
        color: $black;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 0;

        &:hover {
          color: $primary;
        }

        svg{
          font-size: 1.8rem;
        }

        img {
          width: auto;
          height: 1.5rem;
          max-width: 1.5rem;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .header-search-field{
        min-width: 90%;
      }
    }
  }
}

.header-msg {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  p {
    margin-bottom: 0;
    background-color: $gray-light !important;

    span {
      background-color: $gray-light !important;
    }
  }
}