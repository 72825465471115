@font-face {
  font-weight: 100 200;
  font-family: "Inpi-Regular";
  src: url("/fonts/Inpi-Thin.eot") format("embedded-opentype"),
  url("/fonts/Inpi-Thin.ttf") format("truetype"),
  url("/fonts/Inpi-Thin.woff") format("woff"),
  url("/fonts/Inpi-Thin.woff2") format("woff2");
}

@font-face {
  font-weight: 300;
  font-family: "Inpi-Regular";
  src: url("/fonts/Inpi-Light.eot") format("embedded-opentype"),
  url("/fonts/Inpi-Light.ttf") format("truetype"),
  url("/fonts/Inpi-Light.woff") format("woff"),
  url("/fonts/Inpi-Light.woff2") format("woff2");
}

@font-face {
  font-weight: 400 600;
  font-family: "Inpi-Regular";
  src: url("/fonts/Inpi-Regular.eot") format("embedded-opentype"),
  url("/fonts/Inpi-Regular.ttf") format("truetype"),
  url("/fonts/Inpi-Regular.woff") format("woff"),
  url("/fonts/Inpi-Regular.woff2") format("woff2");
}

@font-face {
  font-weight: 700 900;
  font-family: "Inpi-Regular";
  src: url("/fonts/Inpi-Bold.eot") format("embedded-opentype"),
  url("/fonts/Inpi-Bold.ttf") format("truetype"),
  url("/fonts/Inpi-Bold.woff") format("woff"),
  url("/fonts/Inpi-Bold.woff2") format("woff2");
}

h1 {
  color: $primary;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: $blue-dark;
  font-size: 1rem;
}

h2 {
  color: $blue-dark;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

h3 {
  color: $blue-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

h4 {
  color: $blue-dark;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0;
}

.normal {
  font-size: 1rem;
  font-weight: 500;
}

.large {
  font-size: 1.2rem;
  font-weight: bold;
}
