.exam-button {
  &:hover {
    color: green;
  }
  min-width: 0;
  border: 3px solid $green;
  color: $green;
  padding: 0;
  height: 18px;
  width: 18px;
  margin-bottom: 0.3em;
  &.exam-button--filled{
    background-color: $green;
    color: white;
  }

  .fa-check {
    margin-bottom: 7px;
    font-size: 0.65em;
  }

  .loader {
    border: 0;
    color: $black
  }
}