.btn {
  font-weight: bold;
  min-width: 8.5rem;
  margin: auto 0;
  color: white;
  text-transform: uppercase;

  &.home-btn{
    text-transform: unset;
    font-size: 0.85rem;
  }
  
  &.btn-icon {
    min-width: 0;
  }

  &:hover {
    color: $white;
  }

  &.disabled {
    cursor: default;
  }

  &.no-min-width {
    min-width: auto !important;
  }

  &.no-text-uppercase {
    text-transform: inherit;
  }

  @each $name, $value in $theme-colors {
    &.btn-outline-#{$name} {
      color: $value;

      &:hover {
        color: $white;
      }

      &:disabled:hover {
        color: $value;
      }

      &.disabled:hover {
        color: $value;
      }
    }

    &.btn-icon-#{$name}{
      min-width: auto;
      padding: 0;
      color: $value;
      margin: 0;
    }

    &.btn-outlined-icon-#{$name} {
      min-width: 0;
      font-size: 0.7rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: $white;
      color: $value;
      margin: 0;
      -webkit-box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      -moz-box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.btn-circle-icon-#{$name}{
      min-width: 0;
      font-size: 0.7rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: $value;
      color: $white;
      margin: 0;
      -webkit-box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      -moz-box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.btn-link-#{$name} {
      color: $value;
      border: 0;
      font-weight: normal;
      min-width: 0;
      padding: 0;
      text-transform: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.btn-link {
    border: 0;
    font-weight: normal;
    min-width: 0;
    padding: 0;
    text-transform: inherit;
  }

  &.btn-text {
    min-width: 0;
    margin: auto 0;
    padding: 0;
    color: $black !important;
    text-transform: inherit;

    &:focus {
      box-shadow: unset;
    }
  }
  &.btn-override-width {
    width: 100% !important;
  }

  .icon-download{
    margin-bottom: 2px;
  }
}

.btn-count {
  border-radius: 0;
  &.text-primary {
    border-bottom: 1px solid $primary;
  }
}

.loader {
  animation: spin 2s linear infinite;
}

.vertical-middle {
  svg {
    vertical-align: baseline;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg)}
}

