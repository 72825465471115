.addresses-book{
  .subtitle {
    font-weight: 300;
  }

  .btn-add {
    text-transform: none;
  }

  .btn-column {
    text-align: end;
  }

  .address-book-table{
    td {
      border-top: none;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      vertical-align: middle;
    }
  }

  .address-text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
  }
}