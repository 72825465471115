.country-label {
  border: 1px solid $gray-400;
  border-radius: 4px;
  padding: 0.4rem;

  div {
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  .country-checkbox {
    margin: 0;

    &::before {
      border-color: #00798C;
      top: 0.1rem;
      border-radius: 50%;
      border-style: dashed;
      width: 1.2rem;
      height: 1.2rem;
    }

    &::after {
      left: -1.4rem;
    }
  }
}

.country-name{
  font-size: 16px;
  color: $white
}

.country-info {
  border: 1px solid $primary;
}
