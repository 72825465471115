.badge {
  font-size: 0.75rem;
  line-height: 16px;
  min-width: 34px;
  margin-right: 1rem;
  padding: 0.75em;
  
  &--long {
    white-space: normal;
  }

  &.top-right {
    position: absolute;
    right: 0;
    top: 0.5rem;
  }

  &.product-class{
    color: white;
    &.valid{
      background-color: $green;
    }
    &.invalid{
      background-color: $gray-very-dark;
    }
  }

  &-small{
    padding: 6px;
  }
}

@each $name, $value in $theme-colors {
  .badge-#{$name}{
    background-color: $value;
    color: white;
  }
}

.badge-none{
  background-color: $gray-100;
  color: $blue-dark;
}