.file-button {
    border-bottom: 1px solid $gray-middle;
    display: inline-block;
    padding-right: 10px;

    &__text {
        color: $gray-dark;
        margin-right: 30px;
        font-size: 14px;
    }
}