.text-field-inline {
  span {
    border-radius: 5px 0 0 5px;
  }

  input {
    border-radius: 0 5px 5px 0;
  }
}
.absolute-right-icon {
  position: absolute;
  right: 25px;
  bottom: 7px;
}

.absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.absolute-reset {
  position: unset;
  top: unset;
  transform: unset;
  right: unset;
}

.absolute-loading-reset {
  top: unset;
}

.absolute-loading{
  top: 30%;
}

.textfield-prefix{
  border-bottom: 1px solid #DFDFE0;
  height: calc(1.5em + 0.75rem + 4px);
  padding-right: 1px;
}