.btn-column {
  text-align: end;
}

.table-column-label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.priority-table {
  tr:nth-of-type(odd) {
    background-color: white !important;
    td {
      background-color: rgba(0, 0, 0, 0.05);
    }
    td:first-child {
      border-top-left-radius: 5px;
    }
    td:last-child {
      border-top-right-radius: 5px;
    }
    td:first-child {
      border-bottom-left-radius: 5px;
    }
    td:last-child {
      border-bottom-right-radius: 5px;
    }
  }

  td {
    border-top: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    vertical-align: middle;
  }
}