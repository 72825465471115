.basket-count{
  .count-item {

    &.active {
      font-weight: 700;

      &:before {
        position: absolute;
        content: '';
        width: 5px;
        background-color: $secondary;
        height: 30px;
        margin-left: -1rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &:hover {
      font-weight: 700;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-middle;
    }
  }

}