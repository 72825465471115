.internal-reference-input {
    font-family: $font-family-roboto;
    position: relative;
    &::placeholder {
        font-style: italic;
        font-size: 12px;
    }
    &::-webkit-input-placeholder {
        font-style: italic;
        font-size: 12px;
     }
     &:-moz-placeholder {
        font-style: italic;
        font-size: 12px;
     }
    &::-moz-placeholder {
        font-style: italic;
        font-size: 12px;
     }
    &:-ms-input-placeholder {  
        font-style: italic;
        font-size: 12px;
     }
    .valid-icon {
        right: 10px;
    }
}