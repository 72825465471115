.products-and-services {
  padding-bottom: 5em;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-bottom: 10em;
  }

  &__tab {
    font-size: 12px;
  }

  &__table {
    thead > tr > th, tbody > tr > td {
      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        text-align: left;
      }
    }
    tbody > tr > td {
      font-size: 1rem!important;
    }
    .product-name--deletable {
      .btn {
        display: none;
      }
      &:hover {
        .btn {
          display: inline-block;
        }
      }
    }
  }
  &__import {
    height: auto;
  }
}

.products-list__row--edited {
  border-left: 5px solid #FDD131;
}

.products-list__row--deleted {
  border-left: 5px solid #872C2C;
}

@each $name, $value in $theme-products-status {
  .product-#{$name}{
    color: $value;
  }
}