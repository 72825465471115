.titles {
  .btn-add {
    text-transform: initial;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  }
  .title-info{
    font-size: 14px;
    font-weight: 700;
    color: $gray-dark;
  }
}