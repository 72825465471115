.color-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}
.icon-wrapper {
  border: 1px solid #F1F1F1;
  padding: 3px 5px;
}
.icon {
  width: 20px;
}
.color-modal {
  position: absolute;
  right: -70px;
  top: 135%;
  z-index: 91;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 8px #838383;
  border-radius: 8px;
}

.sketch-picker{
  border-radius: 0!important;
  box-shadow: none!important;
}