.form-check-input, .form-check-label {
  cursor: pointer;

  &.custom-control-label {
    &::before {
      border-color: $primary;
      top: 0.20rem;
    }
  }
}
