.container-step {
  .step-list {
    .title {
      text-transform: uppercase;
      font-family: $font-family-roboto;
      font-weight: $font-weight-bold;
      font-size: 15px;
    }

    .guide{
      &.active{
        color: $primary;
      }
      color: $gray-dark;
      font-size: 1rem;
      font-family: $font-family-roboto;
    }

    .guidedMode{
      .react-switch-bg{
        width: 45px !important;
        height: 25px !important;
      }

      .react-switch-handle{
        height: 23px !important;
        width: 23px !important;
      }
    }

    .list {
      .list-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .icone {
          display: none;
          color: $dark;
        }

        .index {
          margin-right: 1rem;
          font-size: 1.2rem;
        }

        &.level-0 {
          &:not(:last-child) {
            border-bottom: 1px solid $gray-middle;
          }
          
          font-family: $font-family-roboto;
          color: $blue-dark;
          font-size: 1rem;
          font-weight: $font-weight-bold;
          padding-top: 1rem;
          padding-bottom: 1rem;

          &.active:before {
            position: absolute;
            content: '';
            width: 5px;
            background-color: $primary;
            height: 50%;
            margin-left: -1rem;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }

        &.level-1 {
          padding-left: 4rem;
          padding-bottom: 0.5rem;
        }

        &.level-2 {
          padding-left: 5rem;
          padding-bottom: 0.5rem;
          font-size: 15px;
        }

        &.last-level-item {
          border-bottom: 1px solid $gray-middle;
        }

        &.active {
          color: $primary;

          .icone {
            display: inline;
          }
        }
      }
    }
  }

  .step-view {
    .card-body {
      display: flex;
      flex-direction: column;

      .step-buttons--fixed{
        .btn{
          margin-right: 5px;
          margin-left: 5px;
        }
      }

      .step-buttons--fixed.fixed-absolute{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .step-buttons--fixed.fixed-bottom{
        left: 50%;
        margin-bottom: 5em;
        right: inherit;

        .step-buttons .bg-white{
          border: 2px solid $gray-700 ;
        }

        @media screen and (max-width: 768px) {
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }
      }

      .step-buttons {
        .btn {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}