.modal-overflow > .modal-content {
  overflow: visible !important;
}

.modal-overflow > .modal-content > .modal-body {
  overflow-y: visible !important;
}

.btn-validate {
  background-color: $gray-very-dark;
}