@import "~@inpi-marques/components/src/styles/global.scss";
@import '~react-toastify/dist/ReactToastify.css';

@import "styles/variables";
@import "styles/global";
@import "styles/fonts";

@import "component/login/login";
@import "component/notFound/notFound";
@import "component/header/header";
@import "component/footer/footer";
@import "component/search/searchField";
@import "component/stepper/stepContainer";
@import "component/payment/payment";
@import "component/deposit/form/common";
@import "component/internalReference/internalReferenceField";
@import "component/count/basketCount";
@import "component/home/home";
@import "component/addressesBook/addressesBook";
@import "component/transactions/notifications/OverviewNotificationsInfo";
@import "component/transactions/overview/overview";
@import "component/opposition/form/registrationRequest/requestIdentification";
@import "component/division/form/productsAndServices/divisionProductAndServiceList";
@import "component/division/form/divisionType";
@import "component/inscription/form/titles/titles";
@import "component/deposit/overview/depositBrand";
@import "component/frmi/form/countries/countries";
@import "component/frmi/form/feesAndTaxes/feesAndTaxes";
@import "component/transactions/notificationsOMPI/notificationsOMPI";
@import "component/frmiPostOperations/form/factsSummary/factsSummary";
@import "component/officialDocument/form/officialDocumentsRequested/officialDocument";
@import "component/officialDocument/form/officialDocumentsRequested/transactionsFromTitle/transactionFromTitle";
@import "component/frmiPostOperations/form/contributor/contributor";