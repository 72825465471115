@mixin vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

//Adapte les couleurs du dropdown
@mixin dropdown-colors($class-name, $color-bg, $color-text, $color-bg-hover, $color-text-hover) {
  &.#{$class-name} {
    .btn-link, .btn-link:hover, .btn-link:focus {
      color: $color-text;
    }

    .header-dropdown-menu {
      background-color: $color-bg;
      max-height: 80vh;
    }

    .header-dropdown-item {
      color: $color-text;
      &:hover, &:focus {
        background-color: $color-bg-hover;
        color: $color-text-hover;
      }
    }

    .disabled {
      color: darken($color-text, 30%)
    }
  }
}

.nav-dropdown {
  @include vertical-center;

  .btn-link, .btn-link:hover, .btn-link:focus {
    color: white;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  .header-dropdown-menu {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $blue-dark;
    z-index: 3;
    white-space: nowrap;
    overflow-y: auto;
    .header-dropdown-item {
      padding: 0.4rem 0.6rem;
      color: white;
      outline: none;
      box-shadow: none;
      text-decoration: none;
      &:hover, &:focus {
        background-color: lighten($blue-dark, 20%);
      }
    }
  }

  @include dropdown-colors('nav-dark', $blue-dark, $white, lighten($blue-dark, 20%), $white);
  @include dropdown-colors('nav-white', $white, $black, $gray-light, $black);
  @include dropdown-colors('nav-grey', $gray-dark, $white, lighten($gray-dark, 20%), $white);
  @include dropdown-colors('nav-primary', $primary, $white, lighten($primary, 10%), $white);
  @include dropdown-colors('nav-light-primary', $white, $black, $white, lighten($primary, 10%));
  @include dropdown-colors('nav-fo-primary', $white, $primary, $gray-100, $primary);
}

.nav-dropdown {
  &.align-right {
    .header-dropdown-menu {
      right: 0;
    }
  }
}