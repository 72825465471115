.division__products-and-services {
    .product-class {
        position: relative;

        background: #EEEFF0;
        padding: 15px 40px 15px 15px;
        border-radius: 5px;

        &__delete-button {
            top: 20px;
            right: 5px;
        }
    }
}