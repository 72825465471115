$primary: #00798C;
$secondary: #FF5A60;
$blue-dark: #171f2a;
$gray-very-dark: #979797;
$gray-dark: #656665;
$gray-middle: #DFDFE0;
$gray-light: #F3F4F6;
$off-white: #FDFDFD;
$inpi-marques: #BCCF00;
$orange: #eb8c2f;
$green: #109357;
$brown: #B86308;
$dark: #000;


$theme-colors: (
  "inpi-marques": $inpi-marques,
  "light-primary": #26A6BA,
  "gris": $gray-dark
);

$gray-100: $gray-light;
$gray-400: $gray-middle;
$gray-700: $gray-dark;
$gray-900: $blue-dark;

$border-radius: 5px;
$border-width: 1px;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-bold: 700;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-inpi: "Inpi-Regular";

$input-bg: $gray-light;
$input-border-width: 2px;
$input-color: $blue-dark;
$input-focus-border-color: $gray-middle;
$input-focus-box-shadow: 0 0 0 .2rem $gray-light;

$custom-control-indicator-border-width: 1px;
$custom-checkbox-indicator-border-radius: 2px;
$custom-select-focus-box-shadow: 0 0 0 .2rem $gray-light;

$highlight-badge-bg-color: #F18700;

$label-margin-bottom: .25rem;

$theme-hover-colors: (
        "primary": lighten($primary, 70%),
        "secondary": lighten($secondary, 31%),
        "inpi-marques": lighten($inpi-marques, 55%),
        "gris": lighten($gray-dark, 55%),
);

$theme-procedure-type-colors: (
        "deposit": #BC383D,
        "inscription": $gray-dark,
        "renunciation": $gray-dark,
        "prorogation": $primary,
        "opposition": #483D8B,
        "nullity": #AA5323,
        "revocation": #234C93,
        "mifr": #437640,
        "division": #510860,
        "frmi": #0475A6,
        "renewal": #A36500,
        "official_document": #109357,
        "revocation_statement": #914B4B
);

$theme-sub-procedure-type-colors: (
        "deposit_type_national": #317FFD,
        "deposit_type_guarantee": #2FB5CD,
        "deposit_type_collective": #FF9854,
        "deposit_type_european": #3CB56A,
        "deposit_type_international": #9161B2,
        "deposit_type_division": #FDD131
);

$theme-notification-status-colors: (
        "draft" : #45987c,
        "toreview": #7aacba,
        "reviewed" : #6e7eb5,
        "validated" : #f09918,
        "toconsider" : #b757ab,
        "considered_fo" : #b757ab,
        "considered" : #b757ab,
        "toanswer" : #b757ab,
        "toanswerompi" : #b757ab,
        "answered" : #ec615e,
        "closedanswered" : #ec615e,
        "closednotanswered" : #c39353,
        "expired" : #c39353,
        "not_considered": #c39353,
        "closed_considered": #ec615e,
        "closed_notconsidered": #ec615e,
        "closed_send": #ec615e,
        "to_close_sent" : #b757ab
);

$theme-notification-type-colors: (
        "expected_response": #45987c,
        "agreement_notification": #ec615e,
        "no_response_expected": #6e7eb5,
);

$theme-products-status:(
        "added-bo": #5572d3,
        "added-fo": $primary,
        "deleted-bo": #872C2C,
        "deleted-fo": $secondary
);

:export { 
        primary: $primary; 
        secondary: $gray-middle; 
        RgbPrimary: rgb(0, 121, 140); 
        RgbSecondary: rgb(255, 90, 96);
        RgbBlack: rgb(0, 0, 0);
        RgbInpi: rgb(188, 207, 0); 
        blue_dark: $blue-dark;
        gray_very_dark: $gray-very-dark;
        gray_dark: $gray-dark;
        gray_middle: $gray-middle;
        gray_light: $gray-light;
        off_white: $off-white;
        marques: $inpi-marques;
        orange: $orange;
        green: $green;
        brown: $brown;
}