.deposit-type {
    .subtitle {
        font-weight: $font-weight-light;
    }
    &__item {
        background: lighten($primary, 70%);
        border-radius: 5px;
        .card {
            padding: 12px 35px;
            height: inherit;
            &.active {
                .card-body {
                    font-weight: $font-weight-bold;
                }
            }
        }
        &-content {
            padding: 20px 45px;
        }

        .form-group {
            input.form-control {
                border: none;
                border-radius: 0;
                background: transparent;
                border-bottom: 1px solid $gray-middle;
                display: inline-block;
                padding-right: 10px;

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    color: $gray-middle;
                }
                &::-webkit-input-placeholder {
                    color: $gray-middle;
                 }
                 &:-moz-placeholder {
                    color: $gray-middle;
                 }
                &::-moz-placeholder {
                    color: $gray-middle;
                 }
                &:-ms-input-placeholder {  
                    color: $gray-middle;
                 }
            }
            .form-label {
                font-size: 14px;
                font-weight: $font-weight-bold;
            }
        }
        .content__description{
            position: relative;
            font-size: 12px;
            &-image {
                height: 21px;
                position: absolute;
                top: 5px;
                left: -30px;
            }
        }
    }
}