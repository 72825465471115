.consent-content {
    color: $gray-dark;
    font-size: 12px;

    .custom-checkbox {
        margin-top: 15px;
    }
    .form-check-label[for="guarantee-consent"],
    .form-check-label[for="collective-consent"] {
        padding-top: 2px;
    }
}
