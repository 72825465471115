.options {
  .subtitle {
    font-weight: $font-weight-light;
  }

  .btn-add {
    text-transform: initial;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .plus-icon {
    height: 20px;
  }
}