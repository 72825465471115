@import "./variables";
@import "./fonts";
@import "~bootstrap/scss/bootstrap.scss";

@import "../block/badge";
@import "../block/cardBlock";
@import "../button/submitButton";
@import "../button/fileButton";
@import "../navigation/filAriane";
@import "../form/formManager";
@import "../form/errors/errorField";
@import "../form/fields/inlineTextField";
@import "../select/selectHolder";
@import "../form/fields/checkboxField";
@import "../form/fields/fileBrowserField";
@import "../table/requestListTable";
@import "../navigation/pagination";
@import "../dropzone/dropzone";
@import "../modal/ModalComponent";
@import "../preview/preview";
@import "../editor/editorComponent";
@import "../dropdown/Dropdown";
@import "../form/fields/textField";
@import "../contributors/contributorForm";
@import "../priorities/prioityForm";
@import "../products/productAndServiceAccordion";
@import "../products/productAndServiceTable";
@import "../opposition/foundations/types/brand/brand-form";
@import "../help/help";
@import "../form/fields/MultipleRadioField";
@import "../opposition/records/modal-show-record";
@import "../transaction/examButton/examButton";

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.border-color-primary {
  border-color: $primary !important;
}

.color-primary {
  color: $primary !important;
}

.text-normalcase {
  text-transform: none !important;
}

//Popin
.modal-content {
  border: 0;
  overflow: visible !important;

  .close {
    color: white;
    opacity: 1;
  }

  .modal-body {
    overflow-y: visible;
  }

  .modal-body, .modal-footer {
    background-color: white;
  }
}

$w: (
        2: 2%,
        5: 5%,
        7: 7%,
        10: 10%,
        13: 13%,
        15: 15%,
        16: 16%,
        17: 17%,
        19: 19%,
        18: 18%,
        20: 20%,
        24: 24%,
        30: 30%,
        36: 36%,
        40: 40%,
        45: 45%,
        49: 49%,
        60: 60%
);

@each $name, $value in $w {
  .w-#{$name} {
    width: $value !important;
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.one-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.min-width-auto {
  min-width: auto;
}

.text-green {
  color: $green;
}

.text-green:hover, .text-green:focus{
  color: $green;
}

.text-red {
  color: $danger;
}

.text-gray-dark {
  color: $gray-dark;
}

.image-with-thumbnail {
  width: 75px;
  text-align: center;
}

.color-white {
  color: white;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.editor-toolbar{
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 29px;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 8px;
}

.fit-content-mw{
  max-width: fit-content;
}

.mw-unset{
  min-width: unset;
}

// Reset de la font
pre {
  font: inherit;
  white-space: pre-wrap;
}