.card {
  height: 100%;
  border: $border-width solid transparent;

  &--no-border {
    border: 0;
  }

  .card-header{
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
  }

  .card-body {
    padding: 2rem;

    .title {
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  &.cursor-pointer {
    border-color: transparent;

    &:hover {
      @each $name, $value in $theme-colors {
        &.theme-#{$name} {
          border-color: $value;
          color: $value;
        }
      }
    }

    &.active {
      @each $name, $value in $theme-colors {
        &.theme-#{$name} {
          border-color: $value;
          background-color: $value;
          color: $off-white;
        }
      }
    }
  }
}
