.payment {
    &-choices {
        .img-payment {
            max-width: 80px;
        }
    }
    &-div {
        width: 60%;
        height: 30vh;
        min-height: 420px;
        min-width: 440px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}