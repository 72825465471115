.help-container {
  background: #F3F8F9;
  &-image {
    max-width: 24px;
    position: absolute;
    top: 22px;
    left: 8px;
  }
}

.auto-size {
  height: auto;
  width: auto;
}