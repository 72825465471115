.home .header-dropdown-menu {
  white-space: normal;
}

.home > .procedureFilter {
  z-index: 4;

  .not-active{
    padding: 1rem;
    &:hover {
      color: $primary;
    }
  }

  .active {
    padding: 1rem;
     color: white;
     background-color: $primary;
   }

  div:not(.active) + .filter:not(:first-child):not(.active){
    padding-left: 0;
    &:before {
      border: solid 1px $gray-middle;
      margin-right:1rem;
      content: '';
    }
  }
}