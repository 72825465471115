table.table {
  table-layout: fixed;

  @each $name, $value in $theme-colors {
    &.color-text-hover-#{$name} tbody tr{
      &:hover {
        td {
        color: $value;
        }
        .text-inherit{
          color: $value!important;
        }
      }
    }
  }

  thead th {
    vertical-align: middle;

    .btn.disabled, .btn:disabled {
      opacity: 1;
    }
  }

  .table-checkbox {
    width: 3rem;

    .form-group {
      margin-bottom: 0;
    }
  }

  .status {
    font-weight: 500;
    color: white;
  }

  .fit-parent {
    max-width: 100%;
  }

  .is-read-width {
    width: 7px;
  }

  @each $name, $value in $theme-procedure-type-colors {
    .procedure-type-#{$name}{
      font-weight: 500;
      color: white;
      background-color: $value
    }
  }

  @each $name, $value in $theme-sub-procedure-type-colors {
    .sub-procedure-type-#{$name}{
      font-weight: 500;
      color: white;
      background-color: $value
    }
  }

  @each $name, $value in $theme-notification-status-colors {
    .notification-status-#{$name}{
      font-weight: 500;
      color: white;
      background-color: $value
    }
  }

  @each $name, $value in $theme-notification-type-colors {
    .notification-type-#{$name}{
      font-weight: 500;
      color: white;
      background-color: $value
    }
  }

  .vertical-banner {
    width: 3px;
  }
}

table.not-fixed {
  table-layout: initial;
}

.little-icon{
  width: 50px;
}

.small {
  .little-icon {
    width: 25px;
  }
}
