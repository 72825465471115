html, body, #root {
  height: 100%;
  font-family: $font-family-roboto;
  font-weight: 300;
}

.global {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  min-height: 100%;
  background-color: $gray-light;
}

.main {
  margin-bottom: $main-footer-height;
}

.mt-07 {
  margin-top: 0.7rem;
}

.mb-025 {
  margin-bottom: 0.25rem;
}

.mt-025 {
  margin-top: 0.25rem;
}

.bg-text-info {
  background-color: $info-bg-color;
}

.Toastify__toast--error {
  background-color: $red;
}

.Toastify__toast-container--top-right {
  width: auto;
}

.Toastify__toast-body {
  padding: 0.5rem;
}

.min-width-auto {
  min-width: auto;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

.btn-more{
  display: flex;
  float: right;
    &:hover{
      text-decoration: none;
    }
    .text-button{
      text-decoration: underline;
    }
}

.text-unset {
  text-transform: none !important;
}

.text-underline {
  text-decoration: underline;
}

table.no-border td{
  border:none;
}