.contested-brand{
  .form-label {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $gray-dark;
  }
}

.request-identification {
  .form-group {
    input.form-control {
      border: none;
      border-radius: 0;
      background: transparent;
      border-bottom: 1px solid $gray-middle;
      display: inline-block;
      padding-right: 10px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: $gray-middle;
      }
      &::-webkit-input-placeholder {
        color: $gray-middle;
      }
      &:-moz-placeholder {
        color: $gray-middle;
      }
      &::-moz-placeholder {
        color: $gray-middle;
      }
      &:-ms-input-placeholder {
        color: $gray-middle;
      }
    }
  }

  .attribute-btn {
    color: $primary;
    background: transparent;
    border: 0;
  }
}