.brand-type {
    &__item {
        .card {
            padding: 35px 20px 20px 35px;

            &.active {
                border: solid 5px $primary;
            }
        }
        &-title {
            font-weight: $font-weight-bold;
            font-size: 20px;
            font-family: $font-family-inpi;
        }
        &-title + .btn-link {
            font-size: 20px;
        }
        &-description {
            font-weight: $font-weight-light;
        }
        .btn-link {
            text-decoration: underline;
        }
        .btn-select {
            font-weight: $font-weight-bold;
            font-size: 14px;
            text-transform: none;
        }
    }
    &-examples {
        &__item-title {
            font-size: 26px;
        }
        &__image {
            max-width: 100%;
            height: auto;
            max-height: 200px;
        }
    }
    .modal-title {
        font-family: $font-family-roboto;
        font-weight: $font-weight-bold;
        font-size: 26px;
    }

    .btnIconKnowMore{
       transform: rotate(180deg);
    }

    .validation-error {
        position: relative;
    }
}