.file-browser-field {
  ul {
    li {
      div {
        position: relative;
        padding-right: 1.5em;

        .attribute-btn {
          max-width: 100%;
          text-align: left;
          word-wrap: break-word;
        }

        .icon-delete {
          margin-left: 10px;
        }
      }
    }
  }
}

.need-min-width {
  min-width: 25%;
}

.mw-100rem {
  max-width: 10rem;
}

.overflow-text {
  overflow-wrap: normal;
  overflow: hidden;
}