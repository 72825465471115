.img-preview {
  max-height: 8cm;
  max-width: 8cm;
}

.img-thumbnail {
  max-height: 3cm;
  max-width: 3cm;
}

.video-thumbnail {
  max-height: 3cm;
  max-width: 3cm;
}

.max-8 {
  max-width: 8cm;
}

.preview {
  &-check {
    color: $green;
  }
  &-wrapper {
    position: relative;
    .fa-trash {
      position: absolute;
      top: 0;
      right: -20px;
    }
    audio {
      width: 8cm;
      max-width: 100%;
    }
  }

  &-wrapper-thumbnail {
    background-color: transparent;
    max-height: 3cm;
    max-width: 3cm;
  }

  &-wrapper-print {
    height: 8cm;
    width: 8cm;
    background: white;
    border: 1px dashed $gray-middle;
    display: flex;

    .img-preview {
      max-height: 100%;
      max-width: 100%;
      margin: auto;
    }
  }
}

.popover-preview {
  min-width: 400px;
  text-align: center;
}
