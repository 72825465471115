.search-field {
  input:focus + .input-group-append {
    background-color: $gray-100;
  }

  .input-group-append {
    padding-left: 10px;

    button {
      border-color: white;
    }
  }
}