.rdw-colorpicker-modal-options {
  overflow: auto;
}

.rdw-colorpicker-modal {
  width: 200px;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.rdw-right-aligned-block * {
  text-align: right !important;
}

.rdw-left-aligned-block * {
  text-align: left !important;
}

.rdw-justify-aligned-block * {
  text-align: justify !important;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.modal-full-width {
  max-width: 100%;
  height: 100%;
}

.editor-padding {
  padding: 10px;
}

#roles {
  z-index: 99;
}

.modal-editor-fullscreen {
  .modal-content {
    .modal-body {
      margin-top: 40px;
      overflow-y: auto;
    }
  }
}