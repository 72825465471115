.product-class {
    &__title {
        font-size: 26px;
        font-weight: 500;
    }
    &__item {
        font-size: 0.75rem;
    }
}

.checkbox-filter-products {
    margin-bottom: -35px;
    width: 50%;
}